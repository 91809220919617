import QRCode from 'qrcode';
import inviteBg from 'asset/img/invite-bg.png';
import { useEffect, useState } from 'react';
import { useRequest } from 'ahooks';
import { getReferralCode } from 'api/user';
import styles from './Invite.module.scss';
import { Toast, Button } from 'antd-mobile';
import { SendOutline } from 'antd-mobile-icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export function Invite() {
  const [inviteUrl, setInviteUrl] = useState('');
  const [inviteImg, setInviteImg] = useState('');
  const { data: referralCode } = useRequest(getReferralCode, {
    onBefore() {
      Toast.show({
        duration: 0,
        maskClickable: false,
        icon: 'loading',
      });
    },
    onSuccess(res) {
      console.log(res);
      Toast.clear();
    },
  });
  useEffect(() => {
    if (referralCode) {
      const origin = window.location.origin;
      const inviteUrl = `${origin}/?code=${referralCode}`;
      setInviteUrl(inviteUrl);
      QRCode.toDataURL(inviteUrl).then((res) => {
        console.log(res);
        setInviteImg(res);
      });
    }
  }, [referralCode]);
  return (
    <div
      className={styles['container']}
      style={{ backgroundImage: `url(${inviteBg})` }}
    >
      <div style={{ height: '10%' }}></div>
      <div className={styles['title-box']}>
        <div>加入我们</div>
        <div>邀请好友</div>
      </div>

      <div className={styles['img-box']}>
        <img src={inviteImg} alt="" />
      </div>
      <div className={styles['code-box']}>
        邀请码:
        <div className={styles['code-content']}>{referralCode}</div>
        <div>
          {referralCode && (
            <CopyToClipboard
              text={referralCode}
              onCopy={() => {
                Toast.show({
                  icon: 'success',
                });
              }}
            >
              <Button size="small" color="primary">
                复制
              </Button>
            </CopyToClipboard>
          )}
        </div>
      </div>
      <div className={styles['url-container']}>
        <h3>邀请链接</h3>
        {referralCode && (
          <CopyToClipboard
            text={inviteUrl}
            onCopy={() => {
              Toast.show({
                icon: 'success',
              });
            }}
          >
            <div className={styles['url-box']}>
              <div className={styles['url']}>{inviteUrl}</div>
              <SendOutline className={styles['icon']} />
            </div>
          </CopyToClipboard>
        )}
      </div>
    </div>
  );
}
