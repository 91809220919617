import React from 'react';
import styles from './App.module.scss';

import { Header } from 'Header/Header';
import { AppRouter } from 'router';
import { AppProvider } from './App.context';

function App() {
  return (
    <AppProvider>
      <main className={styles['main']}>
        <Header></Header>
        <div className={styles['content']}>
          <AppRouter></AppRouter>
        </div>
      </main>
    </AppProvider>
  );
}

export default App;
