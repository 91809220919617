import { Routes, Route } from 'react-router-dom';

import { Home } from 'Home/Home';
import { Invite } from 'Invite/Invite';
import { Order } from 'Order/Order';
import { Invitation } from 'Invitation/Invitation';
import { Reward } from 'Reward/Reward';
import { Withdraw } from 'Withdraw/Withdraw';
import { DeeperChainWallet } from 'DeeperChainWallet/DeeperChainWallet';

export function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<Home></Home>}></Route>;
      <Route path="/invite" element={<Invite></Invite>}></Route>;
      <Route path="/order" element={<Order></Order>}></Route>;
      <Route path="/invitation" element={<Invitation></Invitation>}></Route>
      <Route path="/reward" element={<Reward></Reward>}></Route>
      <Route path="/withdraw" element={<Withdraw></Withdraw>}></Route>
      <Route
        path="/deeperChain"
        element={<DeeperChainWallet></DeeperChainWallet>}
      ></Route>
    </Routes>
  );
}
