import { useRequest } from 'ahooks';
import { cancelOrder, getOrderList, payOrder } from 'api/user';
import { useEffect } from 'react';
import { Toast, Card, Tag, Button, Dialog } from 'antd-mobile';
import styles from './Order.module.scss';
import { OrderDto } from 'api/dto';
import { OrderStatus } from 'api/enum';
import { FileOutline } from 'antd-mobile-icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { transferUsdt } from 'w3/eth';

const colorMap = {
  PENDING_PAYMENT: 'warning',
  NOT_SHIPPED: 'primary',
  PAID: 'primary',
  PAYMENT_FAILED: 'danger',
  SHIPPED: 'success',
  CANCELLED: 'default',
};

export function usePayOrder({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: () => void;
}) {
  const { run: runTransferUsdt, loading: runTransferUsdtLoading } = useRequest(
    (orderId: string, total: number) => {
      return transferUsdt(
        total,
        window.sessionStorage.getItem('address') || '',
      ).then((res) => {
        console.log(res);
        if (res.transactionHash) {
          return payOrder(orderId, res.transactionHash);
        }
        return Promise.reject(res);
      });
    },
    {
      manual: true,
      onBefore() {
        Toast.show({
          maskClickable: false,
          duration: 0,
          icon: 'loading',
        });
      },
      onSuccess(res) {
        console.log(res);
        onSuccess();
        Toast.clear();
      },
      onError(e: any) {
        console.log(e);
        onError();
        Toast.clear();
        Toast.show(e.data ? e.data.message : e.message);
      },
    },
  );

  return {
    pay: runTransferUsdt,
    loading: runTransferUsdtLoading,
  };
}

function OrderItem({
  data,
  onRefresh,
}: {
  data: OrderDto;
  onRefresh: () => void;
}) {
  const { t } = useTranslation();
  const { run: runCancelOrder, loading: runCancelOrderLoading } = useRequest(
    cancelOrder,
    {
      manual: true,
      onSuccess(res) {
        console.log(res);
        onRefresh();
      },
    },
  );
  const { pay, loading: payLoading } = usePayOrder({
    onSuccess: onRefresh,
    onError() {},
  });

  return (
    <div className={styles['order-item']}>
      <Card
        extra={<Tag color={colorMap[data.status]}>{t(data.status)}</Tag>}
        title={
          <div
            className={styles['card-title-box']}
            style={{ fontWeight: 'normal' }}
          >
            <FileOutline style={{ marginRight: '4px', color: '#1677ff' }} />
            {data.product === 'device+NFT' ? '网关+NFT' : 'NFT'}
          </div>
        }
      >
        <div className={styles['card-content']}>
          <div className={styles['content-row']}>
            <div>数量：</div>
            <div>{data.quantity}</div>
          </div>
          <div className={styles['content-row']}>
            <div>单价：</div>
            <div>$ {data.price}</div>
          </div>
          <div className={styles['content-row']}>
            <div>总价：</div>
            <div>$ {data.price * data.quantity}</div>
          </div>

          {data.receiver && (
            <div className={styles['content-row']}>
              <div>收货人：</div>
              <div>{data.receiver}</div>
            </div>
          )}
          {data.address && (
            <div className={styles['content-row']}>
              <div>收货地址：</div>
              <div>{data.address}</div>
            </div>
          )}
          {data.deeperChain && (
            <div className={styles['content-row']}>
              <div>Deeper Chain钱包：</div>
              <div style={{ wordBreak: 'break-all', width: '60%' }}>
                {data.deeperChain}
              </div>
            </div>
          )}
          {data.paymentHash && (
            <div className={styles['content-row']}>
              <div>支付Hash：</div>
              <div style={{ wordBreak: 'break-all', width: '60%' }}>
                {data.paymentHash}
              </div>
            </div>
          )}
          {data.paymentTime && (
            <div className={styles['content-row']}>
              <div>支付时间：</div>
              <div>
                {moment(data.paymentTime).format('YYYY-MM-DD HH:mm:ss')}
              </div>
            </div>
          )}

          {data.trackingNumber && (
            <div className={styles['content-row']}>
              <div>物流单号：</div>
              <div>{data.trackingNumber}</div>
            </div>
          )}
          <div className={styles['content-row']}>
            <div>创建时间：</div>
            <div>{moment(data.createdAt).format('YYYY-MM-DD HH:mm:ss')}</div>
          </div>
          <div className={styles['content-row']}>
            <div>订单编号：</div>
            <div>{data._id}</div>
          </div>
        </div>
        <div className={styles['card-footer']}>
          {data.status === OrderStatus.PENDING_PAYMENT && (
            <Button
              loading={runCancelOrderLoading || payLoading}
              onClick={() => {
                Dialog.confirm({
                  content: '是否取消该订单？',
                  onConfirm: async () => {
                    runCancelOrder(data._id);
                  },
                });
              }}
              fill="outline"
              color="default"
              size="small"
            >
              取消订单
            </Button>
          )}
          {data.status === OrderStatus.PENDING_PAYMENT && (
            <Button
              loading={runCancelOrderLoading || payLoading}
              fill="outline"
              color="primary"
              size="small"
              onClick={() => {
                pay(data._id, data.quantity * data.price);
              }}
            >
              支付
            </Button>
          )}
        </div>
      </Card>
    </div>
  );
}

export function Order() {
  const {
    data: list = [],
    loading: getListLoading,
    run: runGetList,
  } = useRequest(getOrderList, {
    onSuccess(res) {
      console.log(res);
    },
  });

  useEffect(() => {
    if (getListLoading) {
      Toast.show({
        duration: 0,
        icon: 'loading',
      });
    } else {
      Toast.clear();
    }
  }, [getListLoading]);

  return (
    <div className={styles['order-container']}>
      {list.length ? (
        list.map((it: OrderDto) => {
          return (
            <OrderItem
              onRefresh={runGetList}
              key={it._id}
              data={it}
            ></OrderItem>
          );
        })
      ) : (
        <div style={{ textAlign: 'center', paddingTop: 30 }}>
          --- 暂无订单 ---
        </div>
      )}
    </div>
  );
}
