import { useRequest } from 'ahooks';
import { Popup, Input, Button, Toast } from 'antd-mobile';
import { useState } from 'react';
import { setReferer as setRefererApi } from 'api/user';

export function useHook() {
  const [visible, setVisible] = useState(false);
  const [referer, setReferer] = useState('');

  const { run: runSetReferer, loading: setRefererLoading } = useRequest(
    setRefererApi,
    {
      manual: true,
      onSuccess(res) {
        console.log(res);
        if (res) {
          Toast.show({
            icon: 'success',
          });
          setVisible(false);
          setReferer('');
        }
      },
      onError() {
        setVisible(true);
        setReferer('');
      },
    },
  );

  return {
    visible,
    setVisible,
    referer,
    setReferer,
    runSetReferer,
    setRefererLoading,
  };
}

export function RefererPopup({ theHook, address }: Record<string, any>) {
  const { visible, referer, setReferer, runSetReferer, setRefererLoading } =
    theHook;
  return (
    <Popup
      visible={visible}
      bodyStyle={{
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        minHeight: '40vh',
      }}
    >
      <div style={{ padding: 30 }}>
        <div style={{ color: '#999', marginBottom: 5 }}>邀请码：</div>
        <div style={{ border: '1px solid #ddd', padding: '10px 20px' }}>
          <Input
            placeholder="请输入邀请码"
            value={referer}
            onChange={(val) => {
              setReferer(val);
            }}
          />
        </div>

        <div style={{ marginTop: 10 }}>
          <Button
            block
            color="primary"
            fill="outline"
            disabled={!referer}
            loading={setRefererLoading}
            onClick={() => {
              runSetReferer(referer);
            }}
          >
            提交
          </Button>
        </div>
      </div>
    </Popup>
  );
}
