import i18n from 'i18next';
import en from './locale/en';
import zh from './locale/zh';
import res from './locale/res';

function getDefaultLng() {
  let lng = window.localStorage.getItem('lng') || navigator.language;
  if (lng === 'zh-CN') {
    return 'zh';
  }
  return 'en';
}
i18n.init({
  interpolation: { escapeValue: false }, // 避免对翻译内容进行HTML转义
  lng: getDefaultLng(), // 默认语言
  resources: {
    en: {
      translation: Object.assign({}, res.en, en),
    },
    zh: {
      translation: Object.assign({}, res.zh, zh),
    },
  },
});

export default i18n;
