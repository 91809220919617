import { useEffect, useState } from 'react';
import { useRequest } from 'ahooks';
import { getDeeperChainWallet, getTotalAdscInfo } from 'api/user';
import moment from 'moment';
import styles from './DeeperChainWallet.module.scss';
import { SpinLoading, Card, Tag, Button } from 'antd-mobile';
import { getImOnline } from 'api/w3';

export type RewardItemDto = {
  amount?: number;
  _id?: string;
  createdAt?: string;
  address: string;
  lastAdscRewardTime?: number;
  totalReward?: number;
  percent?: number;
  adscRewardCount?: number;
  _owner: boolean;
};

export function DeeperChainWallet() {
  const [list, setList] = useState<RewardItemDto[]>([]);
  const [imOnlineMap, setImOnlineMap] = useState<{
    [key: string]: Boolean | undefined;
  }>({});
  const { loading: listLoading } = useRequest(getDeeperChainWallet, {
    async onSuccess(res) {
      console.log(res);
      const { rewardList, deviceList } = res;
      let deviceMap: {
        [key: string]: {
          address: string;
          haveSet: boolean;
        };
      } = {};
      deviceList.forEach((it: string) => {
        deviceMap[it] = { address: it, haveSet: false };
      });
      let finalList: RewardItemDto[] = [];
      rewardList.forEach((it: RewardItemDto) => {
        if (deviceMap[it.address]) {
          deviceMap[it.address].haveSet = true;
          finalList.unshift({
            ...it,
            _owner: !!deviceMap[it.address],
          });
        } else {
          finalList.push({
            ...it,
            _owner: !!deviceMap[it.address],
          });
        }
      });
      Object.values(deviceMap)
        .filter((it: { address: string; haveSet: boolean }) => !it.haveSet)
        .forEach((it) => {
          finalList.unshift({
            address: it.address,
            _owner: true,
          });
        });
      setList(finalList);
    },
  });

  const {
    data: totalInfo = { reward: 0, count: 0 },
    loading: getTotalLoading,
  } = useRequest(getTotalAdscInfo, {
    onSuccess(res) {
      console.log(res);
    },
  });

  useEffect(() => {
    (async () => {
      if (list.length) {
        let resMap: { [key: string]: Boolean } = {};
        for (let item of list) {
          let imOnlineRes = await getImOnline(item.address);
          console.log(imOnlineRes);
          resMap[item.address] = imOnlineRes;
          setImOnlineMap(Object.assign({}, resMap));
        }
      }
    })();
  }, [list]);
  return (
    <div className={styles['container']}>
      <div className={styles['total-box']}>
        <div className={styles['total-row']}>
          <div>总收益：</div>
          <div style={{ fontSize: 18 }}>{totalInfo.reward} ADSC</div>
        </div>
        <div className={styles['total-row']}>
          <div>自有设备：</div>
          <div style={{ fontSize: 18 }}>{totalInfo.count} 台</div>
        </div>
        <div className={styles['total-row']}>
          <div>收益设备：</div>
          <div style={{ fontSize: 18 }}>{list.length} 台</div>
        </div>
      </div>

      {listLoading || getTotalLoading ? (
        <div className={styles['loading-box']}>
          <SpinLoading></SpinLoading>
        </div>
      ) : (
        <div className={styles['card-container']}>
          {list.map((it: RewardItemDto) => {
            return (
              <div key={it._id || it.address} className={styles['card-box']}>
                <Card
                  title={
                    <div className={styles['card-title-box']}>
                      <div className={styles['card-title']}> {it.address}</div>
                      <div style={{ width: 50 }}>
                        {it._owner === true ? (
                          <Tag color="primary">自有</Tag>
                        ) : (
                          <Tag color="warning">下级</Tag>
                        )}
                        {imOnlineMap[it.address] === true && (
                          <Tag color="success">在线</Tag>
                        )}
                        {imOnlineMap[it.address] === false && (
                          <Tag color="default">离线</Tag>
                        )}
                        {imOnlineMap[it.address] === undefined && (
                          <SpinLoading
                            style={{ '--size': '15px' }}
                          ></SpinLoading>
                        )}
                      </div>
                    </div>
                  }
                >
                  <div className={styles['card-row']}>
                    <div>收益次数</div>
                    <div>{it.adscRewardCount}</div>
                  </div>
                  <div className={styles['card-row']}>
                    <div>总收益</div>
                    <div>{it.totalReward}</div>
                  </div>
                  <div className={styles['card-row']}>
                    <div>收益比例</div>
                    <div>{it.percent}</div>
                  </div>
                  <div className={styles['card-row']}>
                    <div>获得收益</div>
                    <div>{it.amount}</div>
                  </div>
                  <div className={styles['card-row']}>
                    <div>上一次收益时间</div>
                    <div>
                      {it.lastAdscRewardTime
                        ? moment(it.lastAdscRewardTime).format(
                            'YYYY-MM-DD HH:mm:ss',
                          )
                        : '-'}
                    </div>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <Button
                      onClick={() => {
                        window.open(
                          `https://staking.adscredits.com/adsc?deeperChain=${it.address}`,
                        );
                      }}
                      size="small"
                      style={{ fontSize: 12 }}
                    >
                      查看详情
                    </Button>
                  </div>
                </Card>
              </div>
            );
          })}
          {list.length === 0 && (
            <div className={styles['empty-box']}>
              暂无收益记录，入网后第二天才有收益。
            </div>
          )}
        </div>
      )}
    </div>
  );
}
