import { ApiPromise, WsProvider } from '@polkadot/api';
import { Codec, AnyJson } from '@polkadot/types-codec/types';

const deeperChainWss = 'wss://mainnet-deeper-chain.deeper.network';

let globalApi: ApiPromise;

async function getApi() {
  if (globalApi && globalApi.isConnected) {
    return globalApi;
  }
  if (globalApi) {
    await globalApi.disconnect();
  }

  const wsProvider = new WsProvider(deeperChainWss);
  globalApi = await ApiPromise.create({ provider: wsProvider });
  return globalApi;
}

export async function getImOnline(deeperChain: string) {
  const diffBlock = 17280;
  const api = await getApi();
  let lastBlock: Codec | AnyJson = await api.query.system.number();
  lastBlock = lastBlock.toJSON();
  let addressBlock: Codec | AnyJson = await api.query.deeperNode.imOnline(
    deeperChain,
  );
  addressBlock = addressBlock.toJSON();
  console.log(lastBlock, addressBlock);
  if (Number(lastBlock) - Number(addressBlock) > diffBlock) {
    return false;
  }
  return true;
}
