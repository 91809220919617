import fetch from './fetch';
import {
  OrderDto,
  ProductPriceDto,
  CreateOrderDto,
  CreateNftOrderDto,
  CreateOrderResDto,
  CreateNftOrderResDto,
  CreateWithdrawDto,
} from './dto';

export function getLoginCode(address: string): Promise<string> {
  return fetch({
    url: '/auth/loginCode',
    params: {
      address,
    },
  });
}

export type LoginResDto = {
  token: string;
  tokenData: {
    username: string;
    role: string;
    iat: number;
  };
};
export function login(
  address: string,
  signature: string,
): Promise<LoginResDto> {
  return fetch({
    url: '/auth/login',
    method: 'POST',
    data: {
      address,
      signature,
    },
  });
}

export function getReferer(): Promise<string> {
  return fetch({
    url: '/user/referer',
    method: 'GET',
  });
}

export function setReferer(referer: string): Promise<string> {
  return fetch({
    url: '/user/referer',
    method: 'POST',
    data: {
      referer,
    },
  });
}

export function getReferralCode(): Promise<string> {
  return fetch({
    url: '/user/referralCode',
    method: 'GET',
  });
}

export function getPrice(): Promise<ProductPriceDto> {
  return fetch({
    url: '/product/price',
    method: 'GET',
  });
}

export function createOrder(
  createOrderDto: CreateOrderDto,
): Promise<CreateOrderResDto> {
  return fetch({
    url: '/order',
    method: 'POST',
    data: createOrderDto,
  });
}

export function createNftOrder(
  createOrderDto: CreateNftOrderDto,
): Promise<CreateNftOrderResDto> {
  return fetch({
    url: '/order/nft',
    method: 'POST',
    data: createOrderDto,
  });
}

export function getOrderList(): Promise<OrderDto[]> {
  return fetch({
    url: '/order/list',
    method: 'GET',
  });
}

export function cancelOrder(id: string): Promise<OrderDto> {
  return fetch({
    url: `/order/cancel/${id}`,
    method: 'POST',
  });
}

export function payOrder(
  id: string,
  transactionHash: string,
): Promise<OrderDto> {
  return fetch({
    url: `/order/pay/${id}`,
    method: 'POST',
    data: {
      transactionHash,
    },
  });
}

export function getInvitation() {
  return fetch({
    url: `/user/invitation`,
    method: 'GET',
  });
}

export function getReward() {
  return fetch({
    url: '/reward/list',
    method: 'GET',
  });
}

export function getTotalReward() {
  return fetch({
    url: '/reward/total',
    method: 'GET',
  });
}

export function getWithdraw() {
  return fetch({
    url: '/withdraw',
    method: 'GET',
  });
}

export function createWithdraw(data: CreateWithdrawDto) {
  return fetch({
    url: '/withdraw',
    method: 'POST',
    data,
  });
}

export function getWithdrawTotalData(): Promise<{
  usdt: { reward: number; withdraw: number };
  adsc: { reward: number; withdraw: number };
}> {
  return fetch({
    url: '/withdraw/total',
    method: 'GET',
  });
}

export function cancelWithdraw(id: string) {
  return fetch({
    url: '/withdraw/cancel',
    method: 'POST',
    data: {
      id,
    },
  });
}

export function getShippingAddress() {
  return fetch({
    url: '/user/shippingAddress',
    method: 'GET',
  });
}

export function getDeeperChainWallet() {
  return fetch({
    url: '/deeperChain/list',
    method: 'GET',
  });
}

export function getTotalAdscInfo() {
  return fetch({
    url: '/deeperChain/total',
    method: 'GET',
  });
}

export function canInvite() {
  return fetch({
    url: '/user/canInvite',
    method: 'GET',
  });
}
