import { useRequest } from 'ahooks';
import { getInvitation } from 'api/user';
import moment from 'moment';
import styles from './Invitation.module.scss';
import { SpinLoading } from 'antd-mobile';

export function Invitation() {
  const { data: list = [], loading: listLoading } = useRequest(getInvitation, {
    onSuccess(res) {
      console.log(res);
    },
  });
  return (
    <div>
      <div className={styles['total-box']}>
        <div>总邀请人数：</div>{' '}
        <div style={{ fontSize: 18 }}>{list.length}</div>
      </div>
      {listLoading ? (
        <div className={styles['loading-box']}>
          <SpinLoading></SpinLoading>
        </div>
      ) : (
        <div className={styles['table-box']}>
          <table>
            <thead>
              <tr>
                <th>地址</th>
                <th>VIP</th>
                <th>邀请时间</th>
              </tr>
            </thead>
            <tbody>
              {list.map((it: any) => {
                return (
                  <tr key={it.username}>
                    <td>{it.username}</td>
                    <td>{it.priceVip}</td>
                    <td>
                      {it.refererTime
                        ? moment(it.refererTime).format('YYYY-MM-DD HH:mm:ss')
                        : '-'}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
