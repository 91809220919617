import { useRequest } from 'ahooks';
import { cancelWithdraw, createWithdraw, getWithdraw } from 'api/user';
import moment from 'moment';
import styles from './Withdraw.module.scss';
import { SpinLoading, Button, Popup, Form, Input, Tag, Card, Dialog } from 'antd-mobile';
import { useEffect, useState } from 'react';
import { CoinTypeEnum, WithdrawListItem } from 'api/dto';
import { getWithdrawTotalData } from 'api/user';
import { WithdrawStatusEnum } from '../api/dto';
import { useTranslation } from 'react-i18next';

const colorMap: {
  [key: string]: string;
} = {
  [WithdrawStatusEnum.SUBMITTED]: 'primary',
  [WithdrawStatusEnum.APPROVED]: 'primary',
  [WithdrawStatusEnum.TRANSFERRED]: 'success',
  [WithdrawStatusEnum.CANCELLED]: 'default',
  [WithdrawStatusEnum.REJECTED]: 'danger',
};

export function Withdraw() {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [max, setMax] = useState(0);
  const {
    data: list = [],
    loading: listLoading,
    run: runGetList,
  } = useRequest(getWithdraw, {
    onSuccess(res) {
      console.log(res);
    },
  });

  const { run: runCreateWithdraw, loading: runCreateWithdrawLoading } = useRequest(createWithdraw, {
    manual: true,
    onSuccess(res) {
      console.log(res);
      setVisible(false);
      form.resetFields();
      runGetList();
      runGetTotalData();
    },
  });
  const {
    data: withdrawTotalData = {
      usdt: { reward: 0, withdraw: 0 },
      adsc: { reward: 0, withdraw: 0 },
    },
    loading: withdrawTotalDataLoading,
    run: runGetTotalData,
  } = useRequest(getWithdrawTotalData, {
    onSuccess(res) {
      console.log(res);
    },
  });

  const { run: runCancel, loading: runCancelLoading } = useRequest(cancelWithdraw, {
    manual: true,
    onSuccess(res) {
      console.log(res);
      runGetList();
      runGetTotalData();
    },
  });

  return (
    <div>
      <div className={styles['header-box']}>
        <h3>我的通证</h3>

        <div className={styles['header-table']}>
          <table>
            <thead>
              <tr>
                <th>通证</th>
                <th>总收益</th>
                <th>已提现或提现中</th>
                <th>可提现</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>USDT</td>
                <td>{withdrawTotalData.usdt.reward}</td>
                <td>{withdrawTotalData.usdt.withdraw}</td>
                <td>{withdrawTotalData.usdt.reward - withdrawTotalData.usdt.withdraw}</td>
              </tr>
              <tr>
                <td>ADSC</td>
                <td>{withdrawTotalData.adsc.reward}</td>
                <td>{withdrawTotalData.adsc.withdraw}</td>
                <td>{withdrawTotalData.adsc.reward - withdrawTotalData.adsc.withdraw}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={styles['btn-box']}>
          <Button
            color="success"
            loading={withdrawTotalDataLoading}
            disabled={withdrawTotalData.usdt.reward - withdrawTotalData.usdt.withdraw <= 0}
            onClick={() => {
              setVisible(true);
              form.resetFields();
              form.setFieldValue('coin', CoinTypeEnum.USDT);
              let max = parseInt(String(withdrawTotalData.usdt.reward - withdrawTotalData.usdt.withdraw));

              setMax(max);
              form.setFieldValue('amount', max);
            }}
          >
            提现USDT
          </Button>
          <Button
            color="primary"
            loading={withdrawTotalDataLoading}
            disabled={withdrawTotalData.adsc.reward - withdrawTotalData.adsc.withdraw <= 0}
            onClick={() => {
              setVisible(true);
              form.resetFields();
              form.setFieldValue('coin', CoinTypeEnum.ADSC);
              let max = parseInt(String(withdrawTotalData.adsc.reward - withdrawTotalData.adsc.withdraw));

              setMax(max);
              form.setFieldValue('amount', max);
            }}
          >
            提现ADSC
          </Button>
        </div>
      </div>

      <div style={{ margin: 20 }}>
        <Button
          onClick={() => {
            if (window.ethereum) {
              window.ethereum
                .request({
                  method: 'wallet_watchAsset',
                  params: {
                    type: 'ERC20',
                    options: {
                      address: '0x8A7B5Fe6F43DB70affC51f3881fF79354640f3E7', // 要添加的代币合约地址
                      symbol: 'ADSC', // 代币符号
                      decimals: 18, // 代币小数位数
                      image: 'https://www.adscredits.com/ADSC.png', // 代币图标 URL
                    },
                  },
                })
                .catch((e: any) => {
                  console.log(e);
                });
            }
          }}
          size="small"
          color="primary"
          fill="outline"
        >
          如果在钱包看不到ADSC，请点击这里添加ADSC信息到您的钱包
        </Button>
      </div>

      {listLoading ? (
        <div className={styles['loading-box']}>
          <SpinLoading></SpinLoading>
        </div>
      ) : (
        <div className={styles['table-box']}>
          {list.map((data: WithdrawListItem) => {
            return (
              <Card
                key={data._id}
                extra={<Tag color={colorMap[data.status]}>{t(data.status)}</Tag>}
                title={
                  <div className={styles['card-title-box']} style={{ fontWeight: 'normal' }}>
                    <Tag style={{ marginRight: 5 }} color={data.coin === CoinTypeEnum.USDT ? 'success' : 'primary'}>
                      {data.coin}
                    </Tag>
                    {data.amount}{' '}
                  </div>
                }
              >
                <div className={styles['card-content']}>
                  <div className={styles['content-row']}>
                    <div>提现编号：</div>
                    <div>{data._id}</div>
                  </div>
                  <div className={styles['content-row']}>
                    <div>申请时间：</div>
                    <div>{moment(data.createdAt).format()}</div>
                  </div>
                  {data.rejectReason && (
                    <div className={styles['content-row']}>
                      <div>失败原因：</div>
                      <div
                        style={{
                          wordBreak: 'break-all',
                          width: '70%',
                          textAlign: 'right',
                        }}
                      >
                        {data.rejectReason}
                      </div>
                    </div>
                  )}
                  {data.hash && (
                    <div className={styles['content-row']}>
                      <div>转账Hash:</div>
                      <div style={{ wordBreak: 'break-all', width: '70%' }}>{data.hash}</div>
                    </div>
                  )}
                </div>
                <div className={styles['card-footer']}>
                  {[WithdrawStatusEnum.SUBMITTED].indexOf(data.status) !== -1 && (
                    <Button
                      loading={runCancelLoading}
                      onClick={() => {
                        Dialog.confirm({
                          content: '是否取消该申请？',
                          onConfirm: async () => {
                            runCancel(data._id);
                          },
                        });
                      }}
                      fill="outline"
                      color="default"
                      size="small"
                    >
                      取消申请
                    </Button>
                  )}
                </div>
              </Card>
            );
          })}
        </div>
      )}

      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false);
        }}
        onClose={() => {
          setVisible(false);
        }}
      >
        <Form
          form={form}
          layout="horizontal"
          onFinish={values => {
            console.log(values);
            runCreateWithdraw({ ...values, amount: values.amount / 1 });
          }}
          footer={
            <Button loading={runCreateWithdrawLoading} block type="submit" color="primary" size="large">
              提交
            </Button>
          }
        >
          <Form.Item name="coin" label="通证">
            <Input readOnly></Input>
          </Form.Item>

          <Form.Item name="amount" label="数量" rules={[{ required: true, message: '数量不能为空' }]}>
            <Input min={1} max={max} type="number" placeholder="请输入数量" />
          </Form.Item>
        </Form>
      </Popup>
    </div>
  );
}
