import Web3 from 'web3';
import { useEffect } from 'react';
import { getUSDT } from './USDT/USDT';
import BigNumber from 'bignumber.js';

const USDTRecipient = process.env.REACT_APP_RECEIVER;

// 创建一个 Web3 实例
const web3 = new Web3(window.ethereum);
// 请求用户授权访问其钱包

export async function getAccounts(): Promise<string[]> {
  if (!window.ethereum) {
    return [];
  }
  await window.ethereum.enable();
  const accounts = await web3.eth.getAccounts();
  return accounts;
}

export async function signMessage(
  message: string,
  account?: string,
): Promise<string> {
  await window.ethereum.enable();
  if (!account) {
    account = (await getAccounts())[0];
  }
  return await web3.eth.personal.sign(message, account, '');
}

export function checkMetamask(): Boolean {
  return typeof window.ethereum !== 'undefined';
}

export function getChainId(): number {
  return window.ethereum.chainId / 1;
}

export function switchToBsc() {
  return window.ethereum
    .request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: '0x38', // BSC 网络的链ID
          chainName: 'Binance Smart Chain',
          nativeCurrency: {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18,
          },
          rpcUrls: ['https://bsc-dataseed.binance.org/'], // BSC 节点的 URL
          blockExplorerUrls: ['https://bscscan.com/'], // 区块浏览器的 URL
        },
      ],
    })
    .then(() => {
      console.log('Switched to BSC network');
    })
    .catch((error: any) => {
      console.error('Failed to switch network:', error);
    });
}

export function useWeb3Events(
  handleAccountChange: (accounts: string[]) => void,
  handleNetworkChange: (chainId: number) => void,
) {
  useEffect(() => {
    const handleAccountsChanged = (accounts: string[]) => {
      handleAccountChange(accounts);
    };

    const handleChainChanged = (chainId: number) => {
      handleNetworkChange(chainId);
    };

    if (window.ethereum) {
      window.ethereum.on('accountsChanged', handleAccountsChanged);
      window.ethereum.on('chainChanged', handleChainChanged);
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.off('accountsChanged', handleAccountsChanged);
        window.ethereum.off('chainChanged', handleChainChanged);
      }
    };
  }, [handleAccountChange, handleNetworkChange]);
}

export async function transferUsdt(amount: number, sender: string) {
  let chainId = getChainId();
  if (chainId !== 56) {
    await switchToBsc();
  }
  const web3 = new Web3(window.ethereum);
  const USDT = getUSDT(chainId);

  const usdtContract = new web3.eth.Contract(USDT.abi, USDT.address);
  const transferMethod = usdtContract.methods.transfer as any;

  const bigAmount = new BigNumber(amount + '').times('1e18').toFixed();

  const tx = transferMethod(USDTRecipient, bigAmount);
  const gasPrice = await web3.eth.getGasPrice().catch((e) => {
    console.log(e);
  });

  const gas = await tx
    .estimateGas({
      from: sender,
    })
    .catch((e: any) => {
      console.log(e);
    });

  console.log(gas, gasPrice);
  return tx.send({
    from: sender,
    gasPrice: gasPrice ? gasPrice : undefined,
    gas: gas ? gas * 3 : undefined,
  });
}
