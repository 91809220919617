const zh = {
  PENDING_PAYMENT: '未支付',
  NOT_SHIPPED: '未发货',
  SHIPPED: '已发货',
  CANCELLED: '已取消',
  PAID: '已支付',
  PAYMENT_FAILED: '支付失败',

  SUBMITTED: '已提交',
  APPROVED: '审核通过',
  REJECTED: '提现失败',
  TRANSFERRED: '已转账',

  V0: '',
  V1: '分销商',
  V2: '代理商',
  V3: '分公司',
  V4: '合伙人',
  V5: '',
};

export default zh;
