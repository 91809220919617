import React, { useEffect, useState } from 'react';
import { Popup, Form, Input, Button } from 'antd-mobile';
import {
  CreateOrderDto,
  CreateOrderResDto,
  CreateNftOrderDto,
  CreateNftOrderResDto,
} from '../api/dto';
import { createOrder, createNftOrder, getShippingAddress } from 'api/user';
import { useRequest } from 'ahooks';
import { FormInstance } from 'antd-mobile/es/components/form';
import { usePayOrder } from 'Order/Order';
import { useNavigate } from 'react-router-dom';

export function useBuyModal() {
  const [visible, setVisible] = useState(false);
  const [product, setProduct] = useState('');

  const navigate = useNavigate();
  const { pay } = usePayOrder({
    onError() {
      navigate('/order');
    },
    onSuccess() {
      navigate('/order');
    },
  });

  const [form] = Form.useForm();

  const { run: runGetShippingAddress } = useRequest(getShippingAddress, {
    manual: true,
    onSuccess(res) {
      console.log(res);
      form.setFields([
        {
          name: 'address',
          value: res.address,
        },
        {
          name: 'phone',
          value: res.phone,
        },
        {
          name: 'receiver',
          value: res.receiver,
        },
      ]);
    },
  });

  const { run: runCreateOrder, loading: runCreateOrderLoading } = useRequest(
    createOrder,
    {
      manual: true,
      onSuccess(res: CreateOrderResDto) {
        console.log(res);
        if (res._id) {
          pay(res._id, res.quantity * res.price);
        }
      },
    },
  );

  const { run: runCreateNftOrder, loading: runCreateNftOrderLoading } =
    useRequest(createNftOrder, {
      manual: true,
      onSuccess(res: CreateNftOrderResDto) {
        console.log(res);
        if (res._id) {
          pay(res._id, res.quantity * res.price);
        }
      },
    });

  const openBuy = (product: string) => {
    setProduct(product);
    setVisible(true);
    runGetShippingAddress();
  };

  return {
    openBuy,
    visible,
    setVisible,
    runCreateOrder,
    runCreateLoading: runCreateNftOrderLoading || runCreateOrderLoading,
    runCreateNftOrder,
    form,
    product,
  };
}

export type BuyModalDto = {
  theHook: {
    visible: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    runCreateOrder: (createOrderDto: CreateOrderDto) => void;
    runCreateNftOrder: (createOrderDto: CreateNftOrderDto) => void;
    runCreateLoading: boolean;
    product: string;
    form: FormInstance & {
      getFieldWarning: any;
      isFieldValidating: any;
      isFieldsValidating: any;
    };
  };
  priceObj: {
    deviceNft: number;
    nft: number;
  };
};

export function BuyModal({ theHook, priceObj }: BuyModalDto) {
  const {
    visible,
    setVisible,
    runCreateOrder,
    runCreateNftOrder,
    runCreateLoading,
    form,
    product,
  } = theHook;

  const [title, setTitle] = useState('');
  const [priceShow, setPriceShow] = useState('');

  useEffect(() => {
    if (product === 'device+NFT') {
      setTitle('网关+NFT');
    }
    if (product === 'NFT') {
      setTitle('NFT');
    }
  }, [product]);

  useEffect(() => {
    if (priceObj.deviceNft) {
      if (product === 'device+NFT') {
        setPriceShow(`$${priceObj.deviceNft}`);
      }
      if (product === 'NFT') {
        setPriceShow(`$${priceObj.nft}`);
      }
    }
  }, [priceObj, product]);

  return (
    <Popup
      visible={visible}
      onMaskClick={() => {
        setVisible(false);
        form.resetFields();
      }}
      onClose={() => {
        setVisible(false);
        form.resetFields();
      }}
      bodyStyle={{ padding: '20px 0' }}
    >
      {product === 'device+NFT' && (
        <Form
          form={form}
          onFinish={(values) => {
            console.log(values);
            runCreateOrder(
              Object.assign({}, values, {
                quantity: values.quantity / 1,
              }),
            );
          }}
          footer={
            <Button
              loading={runCreateLoading}
              block
              type="submit"
              color="primary"
              size="large"
            >
              提交
            </Button>
          }
        >
          <Form.Header>{title}</Form.Header>
          <Form.Item label="价格">
            <Input readOnly value={priceShow} />
          </Form.Item>
          <Form.Item
            required
            name="quantity"
            label="购买数量"
            rules={[{ required: true }]}
          >
            <Input type="number" min={1} placeholder="请输入购买数量" />
          </Form.Item>
          <Form.Item
            required
            name="receiver"
            label="收件人"
            rules={[{ required: true }]}
          >
            <Input placeholder="请输入收件人" />
          </Form.Item>
          <Form.Item
            required
            name="phone"
            label="手机号码"
            rules={[
              {
                required: true,
                pattern: /^1[3456789]\d{9}$/,
                message: '请输入正确的手机号码',
              },
            ]}
          >
            <Input placeholder="请输入手机号码" />
          </Form.Item>
          <Form.Item
            required
            name="address"
            label="邮寄地址"
            rules={[{ required: true }]}
          >
            <Input placeholder="请输入邮寄地址" />
          </Form.Item>
        </Form>
      )}
      {product === 'NFT' && (
        <Form
          form={form}
          onFinish={(values) => {
            console.log(values);
            runCreateNftOrder(Object.assign({}, values));
          }}
          footer={
            <Button
              loading={runCreateLoading}
              block
              type="submit"
              color="primary"
              size="large"
            >
              提交
            </Button>
          }
        >
          <Form.Header>{title}</Form.Header>
          <Form.Item label="价格">
            <Input readOnly value={priceShow} />
          </Form.Item>

          <Form.Item
            required
            name="deeperChain"
            label="Deeper Chain 钱包地址"
            rules={[{ required: true }]}
          >
            <Input placeholder="请输入Deeper Chain 钱包地址" />
          </Form.Item>
        </Form>
      )}
    </Popup>
  );
}
