import styles from './Header.module.scss';
import { NavBar, Popup, Tag } from 'antd-mobile';
import { MoreOutline } from 'antd-mobile-icons';
import { Link } from 'react-router-dom';
import { useApp } from 'App/useApp';
import { useCanInvite, useTokenData } from 'hooks/hooks';
import { useTranslation } from 'react-i18next';

const vipColor: {
  [key: string]: string;
} = {
  V0: 'primary',
  V1: 'success',
  V2: 'warning',
  V3: 'danger',
  V4: 'danger',
  V5: 'danger',
};

export function Header() {
  const { navbarHook } = useApp();
  const { visible, setVisible } = navbarHook;
  const tokenData = useTokenData();
  const { canInvite } = useCanInvite();
  const { t } = useTranslation();

  return (
    <header className={styles['header']}>
      <NavBar
        back={null}
        right={
          <MoreOutline
            fontSize={30}
            onClick={() => {
              setVisible(true);
            }}
          />
        }
      >
        Deeper Network
      </NavBar>
      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false);
        }}
        position="left"
        bodyStyle={{ width: '60vw' }}
      >
        <div className={styles['user-info-box']}>
          <div className={styles['address']}>{tokenData.username}</div>
          <div className={styles['vip']}>
            <Tag round color={vipColor[tokenData.priceVip]}>
              {tokenData.priceVip} {t(tokenData.priceVip)}
            </Tag>
          </div>
        </div>
        <div className={styles['nav-box']}>
          <Link
            className={styles['nav-item']}
            to="/"
            onClick={() => {
              setVisible(false);
            }}
          >
            首页
          </Link>
          {canInvite && (
            <Link
              className={styles['nav-item']}
              to="/invite"
              onClick={() => {
                setVisible(false);
              }}
            >
              邀请好友
            </Link>
          )}
          {canInvite && (
            <Link
              className={styles['nav-item']}
              to="/invitation"
              onClick={() => {
                setVisible(false);
              }}
            >
              邀请记录
            </Link>
          )}

          {canInvite && (
            <Link
              className={styles['nav-item']}
              to="/reward"
              onClick={() => {
                setVisible(false);
              }}
            >
              订单返佣
            </Link>
          )}
          <Link
            className={styles['nav-item']}
            to="/deeperChain"
            onClick={() => {
              setVisible(false);
            }}
          >
            我的网关
          </Link>
          <Link
            className={styles['nav-item']}
            to="/order"
            onClick={() => {
              setVisible(false);
            }}
          >
            我的订单
          </Link>
          <Link
            className={styles['nav-item']}
            to="/withdraw"
            onClick={() => {
              setVisible(false);
            }}
          >
            提现管理
          </Link>
        </div>
      </Popup>
    </header>
  );
}
