import { useRequest } from 'ahooks';
import { getReward, getTotalReward } from 'api/user';
import moment from 'moment';
import styles from './Reward.module.scss';
import { SpinLoading } from 'antd-mobile';
export type RewardItemDto = {
  amount: number;
  _id: string;
  createdAt: string;
  orderId: string;
};

export function Reward() {
  const { data: list = [], loading: listLoading } = useRequest(getReward, {
    onSuccess(res) {
      console.log(res);
    },
  });

  const { data: totalReward, loading: getTotalLoading } = useRequest(
    getTotalReward,
    {
      onSuccess(res) {
        console.log(res);
      },
    },
  );
  return (
    <div>
      <div className={styles['total-box']}>
        <div>总返佣：</div>{' '}
        <div style={{ fontSize: 18 }}>{totalReward} USDT</div>
      </div>
      {listLoading || getTotalLoading ? (
        <div className={styles['loading-box']}>
          <SpinLoading></SpinLoading>
        </div>
      ) : (
        <div className={styles['table-box']}>
          <table>
            <thead>
              <tr>
                <th>订单编号</th>
                <th>返佣</th>
                <th>返佣时间</th>
              </tr>
            </thead>
            <tbody>
              {list.map((it: any) => {
                return (
                  <tr key={it._id}>
                    <td style={{ wordBreak: 'break-all' }}>{it.orderId}</td>
                    <td>{it.amount} USDT</td>
                    <td>
                      {moment(it.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
