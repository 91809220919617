const res = {
  en: {
    InvalidSignature: 'Invalid Signature',
  },
  zh: {
    InvalidSignature: '钱包签名失败',
    InvalidreferralCode: '邀请码无效',
  },
};

export default res;
