import { OrderStatus } from './enum';
export type OrderDto = {
  _id: string;
  product: string;
  quantity: number;
  receiver?: string;
  address?: string;
  phone?: string;
  status: OrderStatus;
  userId: string;
  paymentAccount?: string;
  receivingAccount?: string;
  paymentHash?: string;
  paymentTime?: number;
  trackingNumber?: string;
  shippingTime?: number;
  referer?: string;
  deeperChain?: string;
  nftHash?: string;
  createdAt: string;
  price: number;
};

export type CreateNftOrderDto = {
  deeperChain: string;
};
export type CreateNftOrderResDto = CreateNftOrderDto & {
  _id: string;
  quantity: number;
  price: number;
};

export type CreateOrderDto = {
  reciever: string;
  phone: string;
  address: string;
};

export type CreateOrderResDto = CreateOrderDto & {
  _id: string;
  quantity: number;
  price: number;
};

export type ProductPriceDto = {
  deviceNft: number;
  nft: number;
};

export enum WithdrawStatusEnum {
  SUBMITTED = 'SUBMITTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  TRANSFERRED = 'TRANSFERRED',
  CANCELLED = 'CANCELLED',
}

export enum CoinTypeEnum {
  USDT = 'USDT',
  ADSC = 'ADSC',
  DPR = 'DPR',
}

export type CreateWithdrawDto = {
  coin: CoinTypeEnum;
  amount: number;
};

export type WithdrawListItem = {
  _id: string;
  coin: string;
  amount: number;
  createdAt: string;
  status: WithdrawStatusEnum;
  rejectReason?: string;
  hash?: string;
};
